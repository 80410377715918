<template>
	<div class="ad-maintenance d-flex flex-column align-items-center justify-content-center">
		<img src="@/assets/images/logo/soca-logo-maintenance.png" alt="SOCA" class="logo-soca">
		<h1 class="ad-maintenance--h1">We’ll be back soon !</h1>
		<h2 class="ad-maintenance--h2">Sorry for the inconvenience but <span class="ad-maintenance--h2-ad">AUTO-DUBBING</span> Studio is under maintenance at the moment. <br> If you need to you can always <span class="ad-maintenance--h2-cu cursor-pointer" @click="directWhatsapp">contact us</span>, otherwise we’ll be back online shortly!</h2>
		<h2 class="ad-maintenance--h2">- Sovia -</h2>
		<button class="ad-maintenance--button btn d-flex align-items-center justify-content-center mt-3" @click="$router.push({ name : 'Home' })">
			<span>Back to Creator</span>
			<svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
				<path d="M7.71429 6.16472e-07L7.21429 0.520631L10.6429 4.16528L-1.78819e-07 4.16528L-1.7882e-07 4.90908L10.6429 4.90908L7.21429 8.47937L7.71429 9L12 4.53718L7.71429 6.16472e-07Z" fill="white"/>
			</svg>
		</button>
	</div>
</template>

<script>
export default {
	methods: {
		directWhatsapp() {
			let phoneNumber = "6282126719955"
            let url = "https://wa.me/" + phoneNumber;
            window.open(url, "_blank");
		},
	},
}
</script>

<style scoped>
.ad-maintenance {
	height: 100vh;
	color: white;
	gap: 24px;
	padding: 0 12px;
}

.logo-soca {
	width: 270.176px;
	height: 228px;
}

.ad-maintenance--h1 {
	color: #FFF;
	font-family: Avenir Next;
	font-size: 40px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.ad-maintenance--h2 {
	color: #FFF;
	text-align: center;
	font-family: Avenir Next;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.ad-maintenance--h2-ad {
	color: #FFF;
	font-family: Avenir Next;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.ad-maintenance--h2-cu {
	color: #6D75F6;
	font-family: Avenir Next;
	font-size: 24px;
	font-style: italic;
	font-weight: 600;
	line-height: normal;
}

.ad-maintenance--button {
	color: #FFF;
	font-family: Avenir Next;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	box-shadow: none;
	padding: 10px 20px;
	gap: 8px;
	border: none;
	background-color: #6D75F6;
	transition: background-color 0.25s;
}

.ad-maintenance--button:hover {
	background-color: #545EED;
}
</style>